.content{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    img{
        margin-bottom: 2rem;
        object-fit: contain;
        width: 80px;
        height: 80px;
    }
    p, h2{
        color: var(--primaryColor);
        text-align: center;
        span{
            color: var(--primaryColor500);
        }
    }
}