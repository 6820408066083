@media (min-width: 1451px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 85vh;
        width: 55%;
        position: relative;
        // background-color: red;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                font-weight: bold;
                font-size: 40px;
                color: var(--primaryColor);
            }

            h6 {
                font-weight: bold;
                margin-top: 1rem;
                color: var(--primaryColor);
                text-align: center;

                span {
                    color: var(--primaryColor100);
                }
            }
        }
    }
}

@media (max-width: 1450px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 70%;
        position: relative;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                font-weight: bold;
                font-size: 40px;
                color: var(--primaryColor);
            }

            h6 {
                font-weight: bold;
                margin-top: 1rem;
                color: var(--primaryColor);
                text-align: center;

                span {
                    color: var(--primaryColor100);
                }
            }
        }
    }
}

@media (max-width: 989px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 50%;
        position: relative;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            h3 {
                font-weight: bold;
                font-size: 40px;
                color: var(--primaryColor);
            }

            h6 {
                font-weight: bold;
                margin-top: 1rem;
                color: var(--primaryColor);

                span {
                    color: var(--primaryColor100);
                }
            }
        }

        p {
            color: var(--primaryColor);
            font-weight: 500;

            a {
                color: var(--primaryColor100);
                text-decoration: underline;
            }
        }
    }
}

.loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 22%;
    width: 100%;
}

.downloadLink {
    position: absolute;
    bottom: 5%;
    text-align: center;
    white-space: nowrap;

    span {
        color: var(--primaryColor);
        font-weight: 500;

        a {
            color: var(--primaryColor100);
            text-decoration: underline;

            &:hover {
                color: var(--primaryColor);
            }
        }
    }
}
