.container {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 0 2%;

    .phoneButton {
        background-color: transparent !important;
        border-right: 1px solid hsl(0, 0%, 80%) !important;
        margin: 10px 0 10px 0 !important;
        z-index: 10000 !important;

        // div {
        //     padding: 0 8px 0 0px !important;
        // }
    }

    .phoneButtonAr {
        background-color: transparent !important;
        border-right: 1px solid hsl(0, 0%, 80%) !important;
        margin: 10px 0 10px 0 !important;
        z-index: 1000 !important;

        div {
            padding: 0 !important;
            margin-right: 5px;

            div {
                div {
                    right: 20px !important;
                    left: 0 !important;
                }
            }
        }
    }

    .phoneNumber {
        width: 100% !important;
        padding: 0.8rem 0.6rem 0.3rem 2.6rem;
        padding-bottom: 0;
        margin: 10px 0 10px 0 !important;
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 2 !important;
        color: var(--primaryColor700);
        background-color: hsl(0, 0%, 100%);
        background-clip: padding-box !important;
        border: 1px solid hsl(0, 0%, 80%);
        appearance: none;
        border-radius: 0.375rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: auto !important;

        &:focus {
            color: var(--primaryColor700);
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }

        &:hover {
            border-color: hsl(0, 0%, 70%);
        }

        &:disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: hsl(0, 0%, 90%);
            border: 1px solid hsl(0, 0%, 80%);
        }
    }

    .phoneNumberAr {
        width: 100% !important;
        padding: 0.8rem 0.6rem 2.6rem;
        padding-bottom: 0;
        margin: 10px 0 10px 0 !important;
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 2 !important;
        color: var(--primaryColor700);
        background-color: hsl(0, 0%, 100%);
        background-clip: padding-box !important;
        border: 1px solid hsl(0, 0%, 80%);
        appearance: none;
        border-radius: 0.375rem !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: auto !important;

        &:focus {
            color: var(--primaryColor700);
            background-color: #fff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }

        &:hover {
            border-color: hsl(0, 0%, 70%);
        }

        &:disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: hsl(0, 0%, 90%);
            border: 1px solid hsl(0, 0%, 80%);
        }
    }

    .codesList {
        width: 350px;
        margin: 2px 0;
    }

    label {
        position: absolute;
        top: 33%;
        left: 0;
        font-size: 14px;
        font-weight: normal;
        color: grey;
        text-transform: capitalize;
        transition: ease-in-out 0.2s;
        cursor: text;
        padding-left: 45px !important;
        z-index: 100;
    }

    .inputFocus {
        top: 18%;
        left: 0;
        font-size: 10px;
    }
}
