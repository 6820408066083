.container {
    display: flex;
    margin: 0 0 0 6%;
}

.dropdown {
    a {
        color: var(--primaryColor700);
        text-decoration: none;
        text-align: left;

        &:after {
            display: none !important;
        }
    }
}
