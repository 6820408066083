@media (min-width: 990px) {
    .section {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .content {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            padding: 0 8%;

            .selectLanguage {
                justify-content: flex-end;
                display: flex;
                align-items: center;
                padding-top: 2%;

                div {
                    svg {
                        margin-right: 3%;
                        color: var(--primaryColor700);

                        &:hover {
                            background-color: var(--grayColor100);
                            border-radius: 15px;
                        }
                    }
                }

                .userData {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .imagePannel {
            display: flex;
            width: 75%;
            // height: 100vh;
            // min-height: auto;

            img {
                width: 100%;
                min-height: 100%;
            }
        }

        div {
            main {
                display: flex;
                justify-content: center;
                align-items: center;

                .image {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 989px) {
    section {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 100%;
        min-height: 100vh;

        .imagePannel {
            display: none;
        }

        .content {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            padding: 0 8%;

            .selectLanguage {
                justify-content: flex-end;
                display: flex;
                align-items: center;
                padding-top: 2%;

                .userData {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                }
            }
        }

        div {
            main {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .image {
                    width: 90px;
                    height: 90px;
                    padding: 10px;
                    border-radius: 50%;
                    background-color: var(--primaryColor);
                    margin-bottom: 2%;

                    img {
                        width: 100%;
                        height: auto;
                        padding: 10px;
                    }
                }
            }
        }
    }
}

.countryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    gap: 8px;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        font-weight: bold;
        font-size: 40px;
        color: var(--primaryColor);
    }

    h6 {
        margin-top: 1rem;
        color: var(--grayColor400);
        text-align: center;
    }
}
