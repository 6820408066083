body {
  margin: 0;
  font-family: "poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primaryColor700);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --primaryColor800: #00163a;
  --primaryColor700: #183a59;
  --primaryColor: #1b4b7a;
  --primaryColor500: #2374bb;
  --primaryColor100: #73a2cc;
  --primaryColor200: #c4e0f5;
  --primaryColor300: #e0ebf5;
  --primaryColor400: #ecf0f2;
  --grayColor100: #e9ecef;
  --grayColor200: #cdd0d3;
  --grayColor300: #d5d5d5;
  --grayColor400: #8c8c8c;
  --grayColor500: #626262;
  --grayColor600: #666666;
  --grayColor700: #e8e8e8;
  --grayColor800: #bebebe;
  --warningColor: #fbc012;
  --warningColor400: rgba(251, 192, 18, 0.2);
  --warningColor100: #f6c01238;
  --warningColor200: #f6c012;
  --warningColor300: #fffadf;
  --successColor: #008000;
  --successColor100: rgba(44, 211, 61, 0.1);
  --successColor200: #e5f6ff;
  --successColor300: #c7e9ca;
  --successColor400: #dfffe4;
  --successColor500: #15a424;
  --errorColor: #ff0000;
  --errorColor100: rgba(255, 50, 50, 0.1);
  --errorColor200: rgb(206, 44, 44, 0.3);
  --errorColor300: rgb(206, 44, 44);
  --errorColor700: #d30000;
  --errorColor800: #ffdede;
  --errorColor900: #cd2b2b;
}

#trialAlert {
  background-color: var(--errorColor100) !important;
  color: var(--errorColor700);
  border-color: var(--errorColor100);
  border-width: 2px;
  width: 65%;
}

// [dir="rtl"] .alert-dismissible .btn-close {
//   float: left;
//   margin: 15px 10px 0 0;
// }

.enStyles {
  direction: ltr;
}

.arStyles {
  direction: rtl;
}

.avatar {
  height: 60px;
  padding: 0 5%;
  border-radius: 50%;
  object-fit: cover;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.name {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.toast-header {
  background-color: white !important;
  border-bottom: 1px solid var(--grayColor100) !important;
}

// .MuiTooltip-popper {
//   z-index: 9999999 !important;
// }

.toast-body {
  background-color: white !important;
}

.dropdownMenu {
  a {
    &:active {
      background-color: var(--grayColor300) !important;
    }
  }
}

.dSZDRP {
  display: contents !important;
  border-radius: 0.375rem;
}

.modal {
  z-index: 10000 !important;
}

.bigModal {
  width: calc(100vw - 10vw) !important;
  max-width: calc(100vw - 10vw) !important;

  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 100vh;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primaryColor);
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--primaryColor700);
        cursor: pointer;
      }
    }
  }
}

// .Calendar__sectionWrapper {
//   height: 100%;
//   min-height: 22.8em !important;
//   max-height: 25.8em !important;
// }

.Calendar > :not(.Calendar__footer) button {
  color: var(--primaryColor);
  font-weight: 600;
}

.Calendar__monthArrow {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 fill=%27%231b4b7a%27 viewBox=%270 0 24 24%27><g class=%27nc-icon-wrapper%27 fill=%27%231b4b7a%27><defs stroke=%27none%27></defs><path class=%27cls-1%27 d=%27M12 23.25V.75%27 fill=%27none%27 stroke=%27%231b4b7a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5px%27></path><path class=%27cls-2%27 d=%27M22.5 11.25L12 .75 1.5 11.25%27 fill=%27none%27 stroke=%27%231b4b7a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5px%27 fill-rule=%27evenodd%27></path></g></svg>") !important;
}

// .gNJUsR {
//   overflow-y: auto !important;

//   &::-webkit-scrollbar {
//     width: 100% !important;
//     height: 0.4em;
//     cursor: pointer;
//   }

//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 2px grey;
//     border-radius: 10px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--primaryColor);
//     border-radius: 3px;
//     cursor: pointer;

//     &:hover {
//       background-color: var(--primaryColor700);
//       cursor: pointer;
//     }
//   }
// }

@media print {
  .rdt_TableBody {
    display: block !important;
    width: auto !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
  }

  .rdt_Table {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 100% !important;
      height: 0.4em;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primaryColor);
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--primaryColor700);
        cursor: pointer;
      }
    }
  }

  // .rdt_TableHead {
  //   // display: table-header-group !important;
  //   position: fixed !important;
  //   top: 100px !important;
  // }
  // .rdt_TableBody {
  //   // display: table-header-group !important;
  //   position: fixed !important;
  //   top: 150px !important;
  // }

  .rdt_TableRow {
    // page-break-inside: avoid;
    // display: table-row-group !important;
    page-break-inside: avoid;
    page-break-after: auto;
    &:hover {
      outline-style: none !important;

      .rdt_TableCell {
        text-align: center;
        background-color: var(--grayColor100) !important;
      }
    }
  }

  // .rdt_TableRow:last-child {
  //   page-break-inside: auto;
  //   page-break-after: always !important;
  //   // display: table-row-group !important;
  //   &:hover {
  //     outline-style: none !important;

  //     .rdt_TableCell {
  //       text-align: center;
  //       background-color: var(--grayColor100) !important;
  //     }
  //   }
  // }
}

.rdt_Table {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 100% !important;
    height: 0.5em;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primaryColor);
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: var(--primaryColor700);
      cursor: pointer;
    }
  }
}

// .rdt_TableCol {
//   min-width: 130px !important;
//   text-align: center;
// }

.rdt_TableRow {
  // page-break-inside: avoid;
  // page-break-before: auto;
  // page-break-after: auto;
  // break-inside: avoid;

  &:hover {
    outline-style: none !important;

    .rdt_TableCell {
      text-align: center;
      background-color: var(--grayColor100) !important;
    }
  }
}

.rdt_TableCell {
  text-align: center;

  &:hover {
    background-color: var(--grayColor100) !important;
  }
}

// .rdt_TableCell:last-child {
//   text-align: center;

//   &:hover {
//     background-color: var(--grayColor100) !important;
//   }
// }

.tooltip {
  z-index: 10000000000000000 !important;

  .tooltip-arrow::before {
    position: absolute !important;
    border-left-color: white !important;
    border-right-color: white !important;
  }

  .tooltip-inner {
    background-color: white;
  }
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}

// .bs-tooltip-auto[placement^=left]{
//   .tooltip-arrow::before {
//     border-left-color: white !important;
//   }
// }

// .bs-tooltip-auto[placement^=right]{
//   .tooltip-arrow::before {
//     border-left-color: red !important;
//   }
// }

// .tooltip::after {
//   position: absolute;
//   left: -7px;
//   top: 43%;
//   content: '';
//   width: 15px;
//   height: 15px;
//   background: #333;
//   transform: rotate(45deg);
// }

.accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.accordion-item {
  border: none !important;
  background-color: transparent !important;
}

.accordion-header {
  padding: 1% !important;
  max-width: fit-content !important;

  button {
    color: var(--primaryColor);
    font-weight: bold;
    padding: 2% 0;
    white-space: nowrap !important;
  }

  button::after {
    color: var(--primaryColor);
    margin: 0 10% !important;
  }
}

.accordion-button {
  background-color: transparent !important;
  white-space: nowrap !important;
}

.accordion-button:focus {
  white-space: nowrap !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  white-space: nowrap !important;
  color: var(--primaryColor) !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: bold;
  padding: 2% 0;
}

.accordion-button:not(.collapsed)::after {
  color: var(--primaryColor);
  margin: 0 10% !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}

.accordion-body {
  padding: 0 !important;
}

.p-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 20%;
}

.p-carousel {
  width: inherit;
}

.p-carousel-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.p-carousel-prev,
.p-carousel-next {
  color: white !important;
  background: var(--primaryColor) !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem var(--primaryColor200) !important;
  }

  &:hover {
    background: var(--primaryColor700) !important;
  }
}

.p-disabled {
  background-color: rgb(153, 153, 153);
  color: white;
  padding: 30%;
  display: none !important;
}

.toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-switch {
  padding-right: 0;
  padding-left: 0;
  color: #626262;
}
// .modal-body {
//   overflow: auto;

//   &::-webkit-scrollbar {
//     width: 0.4em;
//     height: 100vh;
//     cursor: pointer;
//   }

//   &::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 2px grey;
//     border-radius: 10px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--primaryColor);
//     border-radius: 3px;
//     cursor: pointer;

//     &:hover {
//       background-color: var(--primaryColor700);
//       cursor: pointer;
//     }
//   }
// }

@media (max-width: 900px) {
  .avatar {
    width: 69px !important;
    height: 66px !important;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .avatar {
    width: 69px !important;
    height: 66px !important;
    padding: 0;
  }
}
