@media (min-width: 990px) {
    .loginForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
        width: 100%;

        .options {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            padding: 2% 0;

            div {
                color: var(--primaryColor);
                font-weight: 700;
                justify-content: center;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }

        // div {
        //     width: 100%;
        //     display: flex;
        //     justify-content: flex-start;
        //     align-items: center;

            .passwordIcon {
                position: absolute;
                margin: 0 4%;
                z-index: 1000;
                width: 2rem;
                height: 2rem;
                transform: scale(0.8);
            }
        // }

        .signup {
            margin-top: 10px;
            color: var(--grayColor400);
            font-weight: 600;
            a {
                color: var(--primaryColor);
                font-weight: 700;
            }
        }

        .extraInfo {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

@media (max-width: 989px) {
    .loginForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
        width: 100%;

        .options {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            padding: 2% 0;

            div {
                color: var(--primaryColor);
                font-weight: 700;
                justify-content: center;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }

        // div {
        //     width: 100%;
        //     display: flex;
        //     justify-content: flex-start;
        //     align-items: center;

            .passwordIcon {
                position: absolute;
                margin: 0 4%;
                z-index: 1000;
                width: 2rem;
                height: 1.6rem;
                transform: scale(0.8);
            }
        // }

        .extraInfo {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}

.divContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.forgetPassword {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
        text-decoration: none;
        color: var(--primaryColor100);

        &:hover {
            color: var(--primaryColor);
        }
    }
}
